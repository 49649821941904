// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import "~bootswatch/dist/zephyr/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/zephyr/bootswatch";

//font awesome
@import '~@fortawesome/fontawesome-free/css/fontawesome';
@import '~@fortawesome/fontawesome-free/css/regular';
@import '~@fortawesome/fontawesome-free/css/solid';
@import '~@fortawesome/fontawesome-free/css/brands';

//app
@import '../css/app';
@import '../css/media-loader';

@import 'intl-tel-input/build/css/intlTelInput.css';
