/* Copied from bootstrap to handle input file multiple
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-success {
    border: 1px solid #c5dbec;
    background: #d0e5f5;
    font-weight: bold;
    color: #2e6e9e;
}
*/
/* This is copied from https://github.com/blueimp/jQuery-File-Upload/blob/master/css/jquery.fileupload.css */
.fileinput-button {
    position: relative;
    overflow: hidden;
}

.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.thumb {
    height: 80px;
    width: 100px;
    border: 1px solid #000;
}

ul.thumb-Images li {
    width: 120px;
    float: left;
    display: inline-block;
    vertical-align: top;
    height: 120px;
}

.img-wrap {
    position: relative;
    display: inline-block;
    font-size: 0;
}

.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: #d0e5f5;
    padding: 5px 2px 2px;
    color: #000;
    font-weight: bolder;
    cursor: pointer;
    opacity: 0.5;
    font-size: 23px;
    line-height: 10px;
    border-radius: 50%;
}

.img-wrap:hover .close {
    opacity: 1;
    background-color: #ff0000;
}

.FileNameCaptionStyle {
    font-size: 12px;
}

#FileSize {
        width: auto;
        height: 15rem;
        overflow-y: scroll;
}

.loader-preview{
    width: 10rem;
}

.modal-body .loader-preview{
    width: auto;
}

.radio-thumbnail > input {
    display: none;
  }

  .radio-thumbnail > :checked + .img-thumbnail {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  .radio-thumbnail > :disabled + .img-thumbnail {
    opacity: .5;
  }

  .media-edit{
    max-height: 25rem;
  }